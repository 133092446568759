<template>
  <el-main>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="this.type=='edit'?$t('cip.plat.sys.tenant.title.editHeadTitle'):$t('cip.plat.sys.tenant.title.addHeadTitle')"
                 @head-save="headSave()"
                 @head-save-cancel="headSave(true)"
                 @head-cancel="headCancel">
    </head-layout>
    <form-layout :column.sync="column" ref="formLayout" :dataForm.sync="dataForm" v-loading="formLoading"></form-layout>
  </el-main>
</template>
<script>
import {getDetail, add as saveOrUpdate} from "@/api/system/tenant";
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";
import {$t} from '@/lang';

export default {
  components: {FormLayout, HeadLayout},
  data() {
    return {
      imageUrl: '',
      isSaving: true,
      isClose: false,
      itemData: [],
      viewFlag: true,//是否展示按钮
      formLoading: false,
      type: 'view',
      dataForm: {},
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission", "language"]),
    headBtnOptions() {
      let res = [];
      if (['edit', 'add'].includes(this.type)) {
        res.push({
          btnOptType: 'save',
          label: this.$t('cip.cmn.btn.saveBtn'),
          emit: 'head-save',
        });
        res.push({
          btnOptType: 'saveBack',
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: 'head-save-cancel',
        });
      }
      res.push({
        btnOptType: 'cancel',
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: 'head-cancel',
      });
      return res;
    },
    column() {
      return [{
        label: $t("cip.plat.sys.tenant.field.tenantId"),
        prop: "tenantId",
        width: 100,
        search: true,
        addDisplay: false,
        editDisplay: false,
        span: 8,
        disabled: true,
        placeholder: this.$t(`cip.plat.sys.tenant.field.tenantIdMessage`),
      },
        {
          label: $t("cip.plat.sys.tenant.field.tenantName"),
          prop: "tenantName",
          search: true,
          width: 180,
          span: 8,
          disabled: this.viewFlag,
          isGlobal: true, //是否支持国际化
          rules: [{
            required: true,
            validator: this.validateTenantName,
            trigger: ["blur", "change"]
          }]
        },
        {
          label: $t("cip.plat.sys.tenant.field.linkman"),
          prop: "linkman",
          width: 100,
          span: 8,
          search: true,
          readonly: this.viewFlag,
          isGlobal: true, //是否支持国际化
          rules: [{
            required: true,
            validator: this.validateLinkman,
            trigger: ["blur", "change"]
          }]
        },
        {
          label: $t("cip.plat.sys.tenant.field.contactNumber"),
          prop: "contactNumber",
          span: 8,
          readonly: this.viewFlag,
          width: 150,
          rules: [{
            pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: this.$t("cip.cmn.rule.inputWarning_message") + this.$t("cip.plat.sys.tenant.field.contactNumber"),
            trigger: "blur"
          }]
        },
        {
          label: $t("cip.plat.sys.tenant.field.accountNumber"),
          prop: "accountNumber",
          width: 90,
          type: 'number',
          span: 8,
          value: 1,
          rules: [
            {
              type: 'number',
              min: 1,
              message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.sys.tenant.field.min`),
              trigger: "blur",
            }
          ],
          slot: true,
          readonly: this.viewFlag,
          addDisplay: false,
          editDisplay: false,
        },
        {
          label: $t("cip.plat.sys.tenant.field.expireTime"),
          prop: "expireTime",
          width: 180,
          type: "date",
          pickerOptions: {
            disabledDate(time) {
              return time.getTime() < Date.now();
            },
          },
          span: 8,
          slot: true,
          readonly: this.viewFlag,
          addDisplay: false,
          editDisplay: false,
        },
        {
          label: $t("cip.plat.sys.tenant.field.domainUrl"),
          prop: "domainUrl",
          span: 8,
          maxlength: 255,
          showWordLimit: true,
          readonly: this.viewFlag,
          row: true,
          rules: [{
            pattern: /^[^\s]*$/,
            message: this.$t("cip.cmn.rule.removeSpacesWarning"),
          }]
        },
        {
          label: $t("cip.plat.sys.tenant.field.address"),
          prop: "address",
          span: 16,
          row: true,
          readonly: this.viewFlag,
          minRows: 2,
          maxlength: 120,
          showWordLimit: true,
          type: "textarea",
          hide: true,
          rules: [{
            pattern: /^[^\s]*$/,
            message: this.$t("cip.cmn.rule.removeSpacesWarning"),
          }]
        },
        {
          label: $t("cip.plat.sys.tenant.field.backgroundUrl"),
          prop: "backgroundUrl",
          type: 'upload',
          disabled: this.viewFlag,
          listType: 'picture-img',
          dataType: 'string',
          action: '/api/sinoma-resource/oss/endpoint/put-file',
          propsHttp: {
            res: 'data',
            url: 'link',
          },
          hide: true,
          span: 24,
        }
      ];
    },

  },
  methods: {
    //保存
    headSave(clo) {
      this.$refs.formLayout.getForm().validate(valid => {
        if (valid) {
          this.$loading();
          saveOrUpdate(this.dataForm).then(res => {
            let {
              msg,
              data,
            } = res.data;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            ;
            this.dataForm = data;
            this.type = 'edit';
            if (clo) {
              this.headCancel()
            }
          }).finally(() => {
            this.$refs.formLayout.$refs.form.allDisabled = false;
            this.$loading().close();
          })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    formData(id) {
      //加载主表信息
      getDetail(id).then(res => {
        this.dataForm = res.data.data;
      })
    },
    validateTenantName(rule, value, callback) {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
          callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        }
        if (val2.length > 30) {
          callback(new Error($t("cip.plat.sys.tenant.field.tenantName") + $t("cip.cmn.rule.nameLength3Warning")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.tenant.field.tenantName")));
      }
    },
    validateLinkman(rule, value, callback) {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
          callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        }
        if (val2.length > 20) {
          callback(new Error($t("cip.plat.sys.tenant.field.linkman") + $t("cip.cmn.rule.nameLength2Warning")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.tenant.field.linkman")));
      }
    }
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type == 'add') {
      this.viewFlag = false;
    } else if (this.type == 'edit') {
      this.viewFlag = false;
      this.formData(this.$route.query.id);
    } else if (this.type == 'view') {
      this.viewFlag = true;
      this.formData(this.$route.query.id);
    }
  },
};
</script>

<style scoped>

</style>
